<template>
  <div class="form-wrapper">
    <stock-table
      :new-quote-product-id="theProductId"
      class="mb-4"
      module-name="purchase-cr"
      :products="orderItemsTable.products"
      @handleChangeProduct="handleChangeProduct"
    />
    <other-charges is-new-direct />
  </div>
</template>
<script>

// eslint-disable-next-line import/named
import StockTable from '@/views/main/orders/components/stock-table/Stock-Table.vue'
import OtherCharges from '../../common/OtherCharges.vue'

export default {
  name: 'ItemsPurchase',
  components: {
    OtherCharges,
    StockTable,
  },
  data() {
    return {
      theProductId: this.$route.query?.product_id,
    }
  },
  computed: {
    orderItemsTable() {
      return this.$store.state[this.MODULE_NAME].orderItemsTable
    },
  },
  methods: {
    handleChangeProduct(items) {
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, {
        ...this.orderItemsTable,
        products: items,
      })
    },
  },
  setup() {
    const MODULE_NAME = 'purchase-cr'

    return {
      MODULE_NAME,
    }
  },
}
</script>
